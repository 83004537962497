<template>
		<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >密码、字符串在线生成</h2>
		<el-row style="margin-top: 4%;">
		<el-col :sm="12" style="margin: 0 auto; text-align: center;">
			<el-form  :inline="true" >
				<el-form-item label="开头字符">
					<el-input v-model="stringData.prefixCharacter" placeholder="例如:xgjzx"></el-input>
				</el-form-item>
				<el-form-item  label="结尾字符">
					<el-input v-model="stringData.suffixesCharacter"  placeholder="例如:.cn"></el-input>
				</el-form-item> 
				<el-form-item v-model="stringData.includeCharacter" label="包含字符">
					<el-input v-model="stringData.includeCharacter"  placeholder="例如:!@#123"></el-input>
				</el-form-item> 
				<el-form-item label="排除字符">
					<el-input v-model="stringData.removeCharacter"  placeholder="例如:abc123"></el-input>
				</el-form-item> 
				<el-form-item label="生成长度">
					<el-input v-model.number="stringData.length" placeholder="请输入1-50范围整数"></el-input>
				</el-form-item> 
				<el-form-item label="生成数量">
					<el-input v-model.number="stringData.size" placeholder="请输入1-1000范围整数"></el-input>
				</el-form-item>
				<el-checkbox-group  v-model="stringData.checkOption">
					<el-checkbox   label="1">数字</el-checkbox>
					<el-checkbox   label="2">大写字母</el-checkbox>
					<el-checkbox   label="3">小写字母</el-checkbox>
					<el-checkbox   label="4">无重复</el-checkbox>
				 </el-checkbox-group>
				 
			</el-form>
			<el-col :sm = "22"  style="margin: 0 auto; text-align: center;">
				<el-form-item label="">
					<el-input readonly  type="textarea" :rows="stringData.rows" resize='none'  v-model="stringData.text"></el-input>
				</el-form-item>
			</el-col>
			<div style="margin: 0 auto; text-align: center;">
				<el-button id="1001" type="primary" @click="generate()">生成</el-button>
				<el-button  @click="copy(stringData.text)" type="primary" plain>复制</el-button>
			</div>
	</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import {numberule as checknumber} from '../../common/validate'
import {generate as stringGenerate} from './pwdstring'	
import {moduleEvent as moduleaction} from '../../common/actionrecord'	
import {clipboard as copy} from '../../common/actionrecord'	

    let stringData = ref({
		moduleid: 1008,
		prefixCharacter: "",
		suffixesCharacter: "",
		includeCharacter: "",
		removeCharacter: "",
		length: 16,
		size: 10,
		rows: 10,
		checkOption: ['1'],
        text: ""
    })
	moduleaction(stringData.value.moduleid,1);
    let generate = () => {
		//校验代码
		var validresult = checknumber(stringData.value.size,1000);
		if(!validresult.success){
			stringData.value.text = validresult.info;
			return;
		}
		//生成长度校验
		var prefixlength = stringData.value.prefixCharacter.length;
		var suffixeslength = stringData.value.suffixesCharacter.length;
		var includelength = stringData.value.includeCharacter.length;
		var checkvalue=stringData.value.checkOption;
		if(checkvalue==false || (checkvalue.length==1 && checkvalue.includes("4"))){
			stringData.value.text = "( ^___^ )，请勾选组成规则：数字、大写字母、小写字母";
			return;
		}
		if((prefixlength+suffixeslength+includelength) > stringData.value.length){
			stringData.value.text = "( ^___^ )，开头字符+包含字符+结尾字符,已超出生成长度";
			return;
		}
		if(stringData.value.length>50){
			stringData.value.text = "( ^___^ )，生成长度，支持1-50位";
			return;
		}
		stringData.value.text = stringGenerate(
		stringData.value.prefixCharacter,
		stringData.value.suffixesCharacter,
		stringData.value.includeCharacter,
		stringData.value.removeCharacter,
		stringData.value.length,
		stringData.value.size,
		stringData.value.checkOption
		).join("\n");
		//模块事件 1.预览 2.使用
		moduleaction(stringData.value.moduleid,2);
    }
//进入页面，触发数据生成逻辑
generate();	
	
</script>

<style>

</style>