let random = (lower, upper) => {
    return Math.floor(Math.random() * (upper - lower+1)) + lower;
}; 

const string_number = new Array(
	'1','2','3','4','5','6','7','8','9','0'
);

const string_lowercase = new Array(
'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'
);

const string_majuscule = new Array(
	'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'
);

export let generate = (prefixCharacter,suffixesCharacter,includeCharacter,removeCharacter,length,size,checkOption) => {
	let randomlength = length-(prefixCharacter.length + suffixesCharacter.length + includeCharacter.length);
	let randomList = getRandomList(checkOption);
	let stringText = new Array(size)
	.fill()
	.map(() => {
		var randomcharacter="";
		for(var i=0;i<randomlength;i++){
			randomcharacter=randomcharacter + usableCharacter(randomList,removeCharacter,randomcharacter,checkOption);
		}
		var calculateCharacter=includeaddRandomText(includeCharacter,randomcharacter);
		return prefixCharacter+calculateCharacter+suffixesCharacter;
	});
	return stringText;
}

let getRandomList = (checkOption)  => {
	var stringList = [];
	if(checkOption.includes("1")){
		stringList=[].concat(string_number);
	}
	if(checkOption.includes("2")){
		stringList=stringList.concat(string_majuscule);
	}
	if(checkOption.includes("3")){
		stringList=stringList.concat(string_lowercase);
	}
	return stringList;
}

let usableCharacter = (randomList,removeCharacter,charactertext,checkOption)  => {
	var character="";
	for(var i=0; i<1000; i++){
		character=randomList[random(0,randomList.length-1)];
		if(removeCharacter.includes(character)){
			continue;
		}
		if(checkOption.includes("4") && charactertext.includes(character)){
			continue;
		}
		if(charactertext.length==0 && character=="0"){
			continue;
		}
		break;
	}
	return character;
}

let includeaddRandomText = (includeCharacter,randomcharacter)  => {
	let randomList=Array.from(randomcharacter);
	let includeList=Array.from(includeCharacter);
	if(includeList==false){
		return randomcharacter;
	}
	var coalescentList= [].concat(includeList,randomList);
	coalescentList.sort(function() {
		return Math.random() - 0.5 // 随机返回正或负值 达到排序的目的
	            })
	return coalescentList.join(""); 
}
